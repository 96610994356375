import PropTypes from 'prop-types';
import { Box, List, ListItem, Flex, Text } from '@chakra-ui/react';

import { Outline } from '@/components/Outline';

const Carousel = ({ title, description, outlines }) => {
  return (
    <Box>
      {title && (
        <Text
          color='gray.600'
          fontSize='xl'
          fontWeight='bold'
          mb={description ? 0 : 4}
        >
          {title}
        </Text>
      )}
      {description && (
        <Text color='gray.500' fontSize='xs' mb={4}>
          {description}
        </Text>
      )}
      <List data-testid='outlines-container' overflowX='auto'>
        <Flex pb={4}>
          {outlines?.map(outline => (
            <ListItem key={outline.id} mr={4} w='270px'>
              <Outline outline={outline} />
            </ListItem>
          ))}
        </Flex>
      </List>
    </Box>
  );
};

Carousel.defaultProps = {
  title: null,
  description: null,
  outlines: [],
};

Carousel.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  outlines: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Carousel;
