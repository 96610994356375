import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';

const MainWrapper = ({ children, ...styles }) => {
  return (
    <Box
      maxW={{ base: '100%', md: '7xl' }}
      mx={{ md: 'auto' }}
      px={{ base: 6, md: 4 }}
      {...styles}
    >
      {children}
    </Box>
  );
};

MainWrapper.defaultProps = {
  styles: {},
};

MainWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  styles: PropTypes.shape({}),
};

export default MainWrapper;
