import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import useTranslation from 'next-translate/useTranslation';
import debounce from 'lodash.debounce';
import { SearchIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  List,
  ListItem,
  Text,
  useOutsideClick,
} from '@chakra-ui/react';
import getConfig from 'next/config';

import {
  fetchByName,
  setSelectedOption,
  clearSearch,
  getSelectedOption,
  getOptions,
} from '@/stores/search';
import { pages } from '@/utils/pages';

const { publicRuntimeConfig } = getConfig();

const Searchbar = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const inputList = useRef();

  const options = useSelector(state => getOptions(state));
  const selectedOption = useSelector(state => getSelectedOption(state));

  const [inputValue, setInputValue] = useState(selectedOption?.name || '');
  const [displayOptions, setDisplayOptions] = useState(false);

  const { SEARCH_BAR_RESULT_LIMIT } = publicRuntimeConfig;

  useOutsideClick({
    ref: inputList,
    handler: () => setDisplayOptions(false),
  });

  const debouncedFetch = useMemo(
    () => debounce(name => dispatch(fetchByName({ name, limit: SEARCH_BAR_RESULT_LIMIT })), 300),
    [dispatch, SEARCH_BAR_RESULT_LIMIT],
  );

  useEffect(() => {
    if (router.pathname === pages.welcomePath) {
      dispatch(setSelectedOption(null));
      setInputValue('');
    }
  }, [dispatch, router]);

  useEffect(() => {
    if (options && !selectedOption) {
      setDisplayOptions(true);
    } else {
      setDisplayOptions(false);
    }
  }, [dispatch, options, selectedOption]);

  const handleOnChange = value => {
    setInputValue(value);

    if (value?.length >= 3) {
      debouncedFetch(value);
    } else {
      dispatch(clearSearch());
    }

    if (selectedOption) dispatch(setSelectedOption(null));
  };

  const handleSelectOption = (option, category) => {
    dispatch(setSelectedOption({ ...option, category }));
    setInputValue(option.name);
    setDisplayOptions(false);
    router.push({
      pathname: pages.outlinesPath,
      query: { [`${category}Id`]: option.id },
    });
  };

  const handleClear = () => {
    dispatch(clearSearch());
    setInputValue('');
    setDisplayOptions(false);
  };

  const handleGetResults = list =>
    Object.keys(list).filter(category => list[category].length);

  const handleOnEnterKeyPressed = e => {
    if (options && e.key === 'Enter') {
      const searchResults = handleGetResults(options);
      const category = searchResults[0];

      handleSelectOption(options[category][0], category);
    }
  };

  const renderOptions = () => {
    if (!options) return;

    const searchResults = handleGetResults(options);

    return (
      <List
        position='absolute'
        bg='white'
        w='100%'
        borderRadius='md'
        boxShadow='md'
        mt={2}
        px={6}
        pt={4}
        pb={2}
        ref={inputList}
        data-testid='input-list'
      >
        {searchResults.map(category => (
          <Box key={category} mb={2} textAlign='left'>
            <Text mb={2} color='alpha.400' fontSize='12px' fontWeight='bold'>
              {t(`searchbar:${category}`)}
            </Text>
            {options[category].map(option => (
              <ListItem
                key={option.id}
                onClick={() => handleSelectOption(option, category)}
                cursor='pointer'
                borderRadius='md'
                mb={1}
                _hover={{ color: 'alpha.400' }}
                data-testid='input-list-item'
              >
                {option.name}
              </ListItem>
            ))}
          </Box>
        ))}
      </List>
    );
  };

  return (
    <Box position='relative' w={{ base: '100%', md: '2xl' }}>
      <InputGroup borderColor='gray.200'>
        <InputLeftAddon bg='white' px={3}>
          <SearchIcon color='alpha.400' h={4} w={4} />
        </InputLeftAddon>
        <Input
          value={inputValue}
          onChange={e => handleOnChange(e.target.value)}
          onKeyDown={e => handleOnEnterKeyPressed(e)}
          color='gray.600'
          placeholder={t('searchbar:placeholder')}
          bg='white'
          _focus={{ boxShadow: 'none' }}
          data-testid='search-input'
        />
        {inputValue?.length && (
          <InputRightAddon
            onClick={handleClear}
            data-testid='clear-icon'
            bg='white'
            cursor='pointer'
          >
            <CloseIcon color='gray.500' h={3} w={3} />
          </InputRightAddon>
        )}
      </InputGroup>
      {displayOptions && renderOptions()}
    </Box>
  );
};

export default Searchbar;
