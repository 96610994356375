import React from 'react';
import { signIn } from 'next-auth/client';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import { Button } from '@chakra-ui/react';
import { DocumentAttachOutlineIcon } from 'chakra-ui-ionicons';
import { pages } from '@/utils/pages';

const SignUpButton = ({ session, lightButton = false, ...props }) => {
  const { t } = useTranslation();
  const router = useRouter();

  const color = lightButton ? 'alpha.400' : 'white';
  const backgroundColor = lightButton ? 'white' : 'alpha.400';

  const sellYourOutline = () => {
    if (session?.user) {
      router.push(pages.uploadOutline);
    } else {
      const currentUrl = window.location.href;

      history.pushState({}, '', currentUrl);

      return signIn(
        'auth0',
        {
          callbackUrl: router.asPath,
        },
        { screen_hint: 'signup' },
      );
    }
  };

  return (
    <Button
      onClick={sellYourOutline}
      bg={backgroundColor}
      color={color}
      w={{ base: 'full', md: 'fit-content' }}
      mx={{ base: 6, md: 0 }}
      _focus={{ boxShadow: 'none' }}
      leftIcon={
        session && <DocumentAttachOutlineIcon color={color} h={6} w={6} />
      }
      {...props}
    >
      {session ? t('common:sellYourOutline') : t('common:signUp')}
    </Button>
  );
};

export default SignUpButton;
