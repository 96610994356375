import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/client';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';

import {
  fetchUser,
  createNewUser,
  getUser,
  getShouldCreateUser,
  clearUser,
} from '@/stores/user';
import { clearPaymentMethods } from '@/stores/payment';
import { clearBalance } from '@/stores/balance';

import ThemeProvider from '@/providers/ThemeProvider';
import Navbar from '@/layouts/components/Navbar';
import Meta, { MetaPropType } from '@/layouts/components/Meta';

import { pages } from '@/utils/pages';

const MainLayout = ({ children, meta }) => {
  const { pathname, query } = useRouter();
  const [session, loading] = useSession();
  const dispatch = useDispatch();

  const user = useSelector(state => getUser(state));
  const shouldCreateUser = useSelector(state => getShouldCreateUser(state));

  useEffect(() => {
    if (!loading) {
      if (session && !user) {
        const { id } = session.user;

        dispatch(fetchUser(id));
      } else if (!session && user) {
        dispatch(clearUser());
        dispatch(clearPaymentMethods());
        dispatch(clearBalance());
      }
    }
  }, [dispatch, session, user, loading]);

  useEffect(() => {
    if (session && shouldCreateUser) {
      const { id, name, email } = session.user;

      dispatch(createNewUser({ id, name, email }));
    }
  }, [dispatch, session, shouldCreateUser]);

  return (
    <ThemeProvider>
      <Meta {...meta} />
      <Navbar
        isEmpty={pathname === pages.tosPath && !query?.complete}
        showOffer={false}
      />
      <Box
        margin='0 auto'
        pt={14}
        h='full'
      >
        {children}
      </Box>
      <style jsx global>{`
        #__next {
          height: 100%;
        }
      `}</style>
    </ThemeProvider>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  meta: PropTypes.shape(MetaPropType),
};

export default MainLayout;
