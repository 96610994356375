import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { signIn } from 'next-auth/client';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, VStack } from '@chakra-ui/react';
import { useSession } from 'next-auth/client';

import {
  getTopRatedOutlines,
  getRecentUplaodedOutlines,
  getTopRatedOutlinesList,
  getRecentUplaodedOutlinesList,
} from '@/stores/recommendedOutlines';
import { getUser } from '@/stores/user';

import MainWrapper from '@/components/Wrapper';
import Carousel from '@/components/Carousel';
import { SignUpButton } from '@/components/SignUpButton';

const RecommendedOutlinesContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const [session] = useSession();

  useEffect(() => {
    dispatch(getTopRatedOutlines());
    dispatch(getRecentUplaodedOutlines());
  }, [dispatch]);

  const user = useSelector(state => getUser(state));
  const topRatedOutlinesList = useSelector(state =>
    getTopRatedOutlinesList(state),
  );
  const recentUplaodedOutlinesList = useSelector(state =>
    getRecentUplaodedOutlinesList(state),
  );

  const sellYourOutline = () => {
    if (user?.id) {
      router.push('/outlines/upload');
    } else {
      const currentUrl = window.location.href;

      history.pushState({}, '', currentUrl);

      return signIn(
        'auth0',
        {
          callbackUrl: router.asPath,
        },
        { screen_hint: 'signup' },
      );
    }
  };

  const showContent =
    topRatedOutlinesList.length || recentUplaodedOutlinesList.length;

  const styles = showContent && { pt: 10 };

  return (
    <MainWrapper overflow='hidden'>
      <Stack spacing={10} {...styles}>
        {topRatedOutlinesList.length && (
          <Carousel
            title={t('carousel:popular')}
            outlines={topRatedOutlinesList}
          />
        )}
        {recentUplaodedOutlinesList.length && (
          <Carousel
            title={t('carousel:recentUploads')}
            outlines={recentUplaodedOutlinesList}
          />
        )}
      </Stack>
      {!!showContent && (
        <VStack spacing={3} align='center' mt={10} mb={20}>
          <SignUpButton session={session} />
        </VStack>
      )}
    </MainWrapper>
  );
};

export default RecommendedOutlinesContainer;
